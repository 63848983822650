

import {Component, Prop, Vue} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import BorcluVekiliForm from "@/components/forms/BorcluVekiliForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {BorcluVekiliEntity} from "@/entity/BorcluVekiliEntity";

@Component({
  components: {FormDialog, BorcluVekiliForm, DeleteDialog}
})

export default class BorcluVekilList extends Vue {

  @Prop({default: []}) borcluVekilleri!: Array<BorcluVekiliEntity>;
  @Prop() update!: boolean;

  headers = [
    {text: "Adı", value: "ad"},
    {text: "Soyad", value: "soyad"},
    {text: "Teblig Tarihi", value: "teblig_tarihi"},
    {text: "İşlemler", value: "actions", width: 100, sortable: false, align: 'center'}
  ];

  load() {
    this.$emit("load");
  }
}
