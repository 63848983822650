

import {Component, Mixins, Prop, Ref, Watch} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import KisiKurumForm from "@/components/forms/KisiKurumForm.vue";
import TarafSifatiPicker from "@/components/pickers/TarafSifatiPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import {KisiKurumTuru} from "@/enum/KisiKurumTuru";
import {BorcluEntity} from "@/entity/BorcluEntity";
import {KisiEntity} from "@/entity/KisiEntity";
import {KurumEntity} from "@/entity/KurumEntity";
import {AdresEntity} from "@/entity/AdresEntity";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import BorcluVekilList from "@/components/lists/BorcluVekilList.vue";
import BorcluVekiliForm from "@/components/forms/BorcluVekiliForm.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";


@Component({
  components: {
    BorcluVekiliForm,
    ItemArrayManager,
    BorcluVekilList,
    FormWrapper,
    Tutar,
    KisiKurumForm,
    TarafSifatiPicker
  }
})
export default class BorcluForm extends Mixins(ObjectInputMixin) {
  @Prop({required: true}) takipId!: number;
  @Prop() borclular!: Array<BorcluEntity>;
  @Prop({default: false}) updateBorcluInfo !: boolean;
  @Ref('formWrapper') formWrapper!: FormWrapper;
  searched: boolean = false;
  existings: Array<KisiEntity> | Array<KurumEntity> = [];
  adresler: Array<AdresEntity> = [];

  adrescheck: boolean = false;

  headers: Array<ItemArrayManagerTableHeader> = [
    {text: "Adı", value: "ad", renderer: (row) => (row?.ad)},
    {text: "Soyad", value: "soyad", renderer: (row) => row?.soyad},
    {text: "Teblig Tarihi", value: "teblig_tarihi", renderer: (row) => this.$helper.tarihStr(row?.teblig_tarihi)}
  ];

  mounted() {
    this.adrescheck = false;
    if (this.localValue) {
      if (this.localValue.id) {
        this.searched = true;
      }
    }
    if (this.borclular) {
      this.existings = this.borclular.map((borclu: BorcluEntity) => {
        return borclu.kisi ?? (borclu.kurum ?? null);
      });
    }
  }


  onAdresCheck() {
    if (this.localValue.kisi.adresler.length > 0) {
      this.adrescheck = false;
    } else {
      this.adrescheck = true;
    }
  }

  onAdresCheckKurum() {
    if (this.localValue.kurum.adresler.length > 0) {
      this.adrescheck = false;
    } else {
      this.adrescheck = true;
    }
  }


  @Watch("borclular.length")
  onItemsChange() {
    this.existings = this.borclular.map((borclu: BorcluEntity) => {
      return borclu.kisi ?? (borclu.kurum ?? null);
    });
  }

  setDefaultFields() {
    if (!this.localValue.sorumluluk_siniri_var_mi) {
      this.localValue.sorumluluk_siniri_var_mi = false;
    }
    if (!this.localValue.tereke_mi) {
      this.localValue.tereke_mi = false;
    }
  }

  onKisiKurumChange() {
    this.searched = true;
  }

  onClose() {
    this.formWrapper.onFly = false;
    this.$emit("onClose");
  }

  resetForm() {
    if (!this.localValue.id) {
      delete this.localValue.kisi;
      delete this.localValue.kurum;
      this.localValue.kisi_kurum_turu = KisiKurumTuru.sahis;
      this.searched = false;
    }
    this.$emit("onClose");
  }

  async vekilUpdate(borcluID: number) {
    for (const vekil of this.localValue.vekiller) {
      let response = await this.$http.post('/api/v1/borclu/' + borcluID + '/vekil', vekil).catch(error => StatusCodeHelper.error(error));
      await this.$http.post('/api/v1/borclu-vekili/' + response.data.id + '/adres', vekil.adres).catch(error => StatusCodeHelper.error(error));
    }
  }

  async save() {
    this.formWrapper.onFly = true;
    this.setDefaultFields();
    this.$store.state.createTcKimlikErrors = [];
    if (this.localValue.kurum_id || this.localValue.kisi_id) {
      this.updateBorclu();
    } else {
      if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sahis) {
        if (this.localValue.kisi.id === 0) {
          this.localValue.kisi.id = null;
        }
        if (this.localValue.kisi.id == null) {
          this.insertKisi();
        } else {
          this.localValue.kisi_id = this.localValue.kisi.id;
          await this.insertBorclu().then(() => {
            this.$emit("onSuccess");
            this.resetForm();
          }).finally(() => (this.formWrapper.onFly = false));
        }
      } else if (this.localValue.kisi_kurum_turu == KisiKurumTuru.sirket) {
        if (this.localValue.kurum.id === 0) {
          this.localValue.kurum.id = null;
        }
        if (this.localValue.kurum.id == null) {
          this.insertKurum();
        } else {
          this.localValue.kurum_id = this.localValue.kurum.id;
          await this.insertBorclu().then(() => {
            this.$emit("onSuccess");
            this.resetForm();
          }).finally(() => (this.formWrapper.onFly = false));
        }
      }
    }
  }

  private insertKisi() {

    this.onAdresCheck();
    if (!this.adrescheck) {
      StatusCodeHelper.dontShow422Alert = true;
      this.$http.post('/api/v1/kisi/', this.localValue.kisi).then(response => {
        this.localValue.kisi_id = response.data.id;
        let promises: Array<Promise<any>> = [];
        promises.push(this.insertBorclu());
        Promise.all(promises).then(() => {
          this.$emit("onSuccess");
          this.resetForm();
        });
        StatusCodeHelper.dontShow422Alert = false;
      }).catch((error) => {
        if (error.response.status == 422) {
          this.$store.state.createTcKimlikErrors = error.response.data.errors;
        }
        StatusCodeHelper.dontShow422Alert = false;
      }).finally(() => (this.formWrapper.onFly = false));
    } else {
      this.formWrapper.onFly = false
    }
  }

  private insertKurum() {
    this.onAdresCheckKurum();
    if (!this.adrescheck) {
      this.$http.post('/api/v1/kurum/', this.localValue.kurum).then(response => {
        this.localValue.kurum_id = response.data.id;
        this.insertBorclu().then(() => {
          this.$emit("onSuccess");
          this.resetForm();
        }).finally(() => (this.formWrapper.onFly = false));
      }).finally(() => (this.formWrapper.onFly = false));
    } else {
      this.formWrapper.onFly = false
    }
  }

  insertBorclu() {
    return this.$http.post('/api/v1/takip/' + this.takipId + '/borclu', this.localValue).then((response) => {
      if (this.localValue.kisi_id && this.localValue.kisi?.adresler.length > 0) {
        this.localValue.kisi.adresler.forEach((adres: AdresEntity) => {
          if (adres.id)
            this.$http.put('/api/v1/adres/' + adres.id, adres);
          else
            this.$http.post('/api/v1/kisi/' + this.localValue.kisi_id + '/adres', adres);
        });
        this.onAdresCheck()
      } else if (this.localValue.kurum_id && this.localValue.kurum?.adresler.length > 0) {
        this.localValue.kurum.adresler.forEach((adres: AdresEntity) => {
          if (adres.id)
            this.$http.put('/api/v1/adres/' + adres.id, adres);
          else
            this.$http.post('/api/v1/kurum/' + this.localValue.kurum_id + '/adres', adres);
        });
        this.onAdresCheckKurum()
      }
      let borcluID = response.data.id;
      this.vekilUpdate(borcluID);
    });
  }

  async updateKisi() {
    const response = await this.$http.put('/api/v1/kisi/' + this.localValue.kisi_id, this.localValue.kisi);
    for (const adresIndex in this.localValue.adresler) {
      const adres = this.localValue.adresler[adresIndex] as AdresEntity;
      if (adres.id)
        await this.$http.put('/api/v1/adres/' + adres.id, adres);
      else
        await this.$http.post('/api/v1/kisi/' + this.localValue.kisi_id + '/adres', adres);
    }
    this.$emit("onSuccess");
    this.resetForm();
  }

  async updateKurum() {
    const response = await this.$http.put('/api/v1/kurum/' + this.localValue.kurum_id, this.localValue.kurum);
    for (const adresIndex in this.localValue.adresler) {
      const adres = this.localValue.adresler[adresIndex] as AdresEntity;
      if (adres.id)
        await this.$http.put('/api/v1/adres/' + adres.id, adres);
      else
        await this.$http.post('/api/v1/kurum/' + this.localValue.kurum_id + '/adres', adres);
    }
    this.$emit("onSuccess");
    this.resetForm();
  }

  updateBorclu() {
    this.$http.put('/api/v1/borclu/' + this.localValue.id, this.localValue).then(() => {
      if (this.localValue.kisi) {
        this.updateKisi();
      } else if (this.localValue.kurum) {
        this.updateKurum();
      }
    }).finally(() => (this.formWrapper.onFly = false));
  }
}
