
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import AdresTuruPicker from "@/components/pickers/AdresTuruPicker.vue";
import SehirPicker from "@/components/pickers/SehirPicker.vue";
import IlcePicker from "@/components/pickers/IlcePicker.vue";
import NoterPicker from "@/components/pickers/NoterPicker.vue";
import KiraAlacakKalemiForm from "@/components/forms/alacak-kalemi/KiraAlacakKalemiForm.vue";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import Tutar from "@/components/inputs/Tutar.vue";
import KiraSozlesmesiTahliyeTaahhuduAlacakFormWizard
  from "@/components/forms/form-wizard/alacak/KiraSozlesmesiTahliyeTaahhuduAlacakFormWizard.vue";
import {AdresTuru} from "@/enum/AdresTuru";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import DovizTakipSekliPicker from "@/components/pickers/DovizTakipSekliPicker.vue";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import Kur from "@/components/inputs/Kur.vue";
import ProtestoIhtarForm from "@/components/forms/ProtestoIhtarForm.vue";
import {TakipTarihliKurEntity} from "@/entity/TakipTarihliKurEntity";
import YevmiyeNumarasi from "@/components/inputs/YevmiyeNumarasi.vue";

@Component({
  components: {
    YevmiyeNumarasi,
    ProtestoIhtarForm,
    DovizTakipSekliPicker,
    Kur,
    ParaBirimiPicker,
    KiraSozlesmesiTahliyeTaahhuduAlacakFormWizard,
    KiraAlacakKalemiForm,
    IlcePicker,
    SehirPicker,
    NoterPicker,
    AdresTuruPicker,
    ItemArrayManager,
    Dates,
    Tutar,
  },
})
export default class KiraSozlesmesiTahliyeTaahhuduAlacakForm extends Mixins(ObjectInputMixin) {
  @Prop() errorAlacakKalemleri!: boolean;
  @Prop() takipTarihliKurlar!: Array<TakipTarihliKurEntity>;

  sozlesmeTuru = SozlesmeTuru;
  adresTuru = AdresTuru;
  paraBirimi = ParaBirimi;
  dovizTakipSekli = DovizTakipSekli;
  isWizardComplete: boolean = false;
  sehirAdi!: string;
  ilceAdi!: string;

  headers: Array<ItemArrayManagerTableHeader> = [
    {
      value: "alacak_kalemi_turu",
      text: "Alacak Kalemi Türü",
      renderer: (row) => row.alacak_kalemi_turu && row.alacak_kalemi_turu.isim,
    },
    {
      value: "faiz_baslangic_tarihi",
      text: "Faiz B. Tarihi",
      renderer: (row) => this.$helper.tarihStr(row.faiz_baslangic_tarihi),
    },
    {
      value: "takip_oncesi_faiz_turu.isim",
      text: "Takip Öncesi Faiz",
      renderer: (row) =>
          row.elle_faiz_mi ? ('Elle Faiz ' + this.$helper.tutarStr(row.elle_faiz_tutari)) : (row.takip_oncesi_faiz_turu ? row.takip_oncesi_faiz_turu.isim : 'Yasal Faiz'),
    },
    {
      value: "takip_sonrasi_faiz_turu.isim",
      text: "Takip Sonrası Faiz",
      renderer: row => row.takip_sonrasi_faiz_turu ? row.takip_sonrasi_faiz_turu.isim : 'Yasal Faiz',
    },
    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },
  ];

  ihtarHeaders: Array<ItemArrayManagerTableHeader> = [
    {
      value: "tarih",
      text: "Tarih",
      renderer: (row) => this.$helper.tarihStr(row.tarih),
    },

    {
      value: "tutar",
      text: "Tutar",
      renderer: (row) => this.$helper.tutarStr(row.tutar, row.para_birimi),
    },

    {
      value: "noter",
      text: "Noter",
      renderer: (row) => (row.noter ? row.noter.isim : null),
    },
  ];

  mounted() {
    if (this.localValue.uzerindeki_pul_degeri == null) {
      this.localValue.uzerindeki_pul_degeri = "0,00";
    }
    if (this.localValue.olmasi_gereken_pul_degeri == null) {
      this.localValue.olmasi_gereken_pul_degeri = "0,00";
    }
    this.localValue.takip_tarihli_kurlar = this.takipTarihliKurlar;
    this.input();
  }

  get tarih() {
    if (this.localValue.sozlesme_tarihi) {
      return this.localValue.sozlesme_tarihi;
    }
  }
  //açıklama kullanıcıya bırakıldı, kendisi girecek
  // get aciklama() {
  // if (this.localValue.adres_detayi && this.localValue.sozlesme_baslangic_tarihi && this.localValue.sozlesme_tarihi && this.localValue.sozlesme_bitis_tarihi && this.localValue.bir_yillik_kira_bedeli) {
  //   let adres = this.localValue.adres_turu_id == AdresTuru.mernis ? 'Mernis' : ((this.localValue.adres_detayi ?? '') + ' ' + this.ilceAdi?.toUpperCase() + '/' + this.sehirAdi?.toUpperCase());
  //   let hazirlanisTarihi = this.$helper.tarihStr(this.localValue.sozlesme_tarihi ?? '');
  //   let baslangicTarihi = this.$helper.tarihStr(this.localValue.sozlesme_baslangic_tarihi ?? "");
  //   let bitisTarihi = this.$helper.tarihStr(this.localValue.sozlesme_bitis_tarihi ?? '');
  //   let aciklama = adres + ' adresinde bulunan kiralanmış taşınmaz için düzenlenmiş; ' + hazirlanisTarihi + ' Hazırlanış Tarihli, ' + baslangicTarihi + ' Başlangıç Tarihli, ' + bitisTarihi + ' Sonlanma Tarihli ' + this.$helper.tutarStr(this.localValue.bir_yillik_kira_bedeli, this.localValue.para_birimi) + ' Meblağlı Kontrat.';
  //   return aciklama;
  // }
  // }

  /*get adres_detayi() {
    if (this.localValue.sehir_id && this.localValue.ilce_id)
      return this.sehirAdi + " ili " + this.ilceAdi + " ilçesi ...";
  }*/

  get maxDate() {
    let yesterday = new Date().setDate(new Date().getDate() - 1)
    return new Date(yesterday).toISOString().substr(0, 10);
  }

  get minDate() {
    if (this.localValue.sozlesme_baslangic_tarihi) {
      let yesterday = new Date(this.localValue.sozlesme_baslangic_tarihi).setDate(new Date(this.localValue.sozlesme_baslangic_tarihi).getDate() + 1)
      return new Date(yesterday).toISOString().substr(0, 10);
    } else return null;
  }

  kontratTarihRule() {
    if (this.localValue.sozlesme_bitis_tarihi) {
      if (new Date(this.localValue.sozlesme_baslangic_tarihi).getTime() < new Date(this.localValue.sozlesme_bitis_tarihi).getTime()) {
        return true;
      } else
        return 'Sözleşme bitiş tarihi, sözleşme başlangıç tarihinden sonra olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  kontratBaslangicTarihRule() {
    if (this.localValue.sozlesme_baslangic_tarihi) {
      if (new Date(this.localValue.sozlesme_baslangic_tarihi).getTime() < new Date().getTime()) {
        return true;
      } else
        return 'Sözleşme giriş tarihi, bugünün tarihiden önce olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  kontratImzaTarihRule() {
    if (this.localValue.sozlesme_tarihi) {
      if (new Date(this.localValue.sozlesme_tarihi).getTime() < new Date().getTime()) {
        return true;
      } else
        return 'Kontrat imza tarihi, bugünün tarihiden önce olmalıdır.';
    } else
      return 'Lütfen bu alanı doldurun.';
  }

  @Watch("tarih")
  onVadeTarihiChange() {
    this.localValue.sozlesme_baslangic_tarihi = this.tarih;
  }

  @Watch('localValue.doviz_kur_tarihi')
  onDovizTakipSekliChange() {
    this.addParaBirimi();
  }

  @Watch('localValue.para_birimi_id')
  onParaBirimiIdChange() {
    this.addParaBirimi();
  }

  @Watch('localValue')
  onLocalValueChange() {
    if (!this.localValue.takip_tarihli_kurlar) {
      this.localValue.takip_tarihli_kurlar = this.takipTarihliKurlar;
    }
  }

  onParaBirimiChange() {
    if (this.localValue.para_birimi_id == this.paraBirimi.TRY)
      delete this.localValue.doviz_kur_tarihi;
    this.addParaBirimi();
    this.input();
  }

  addParaBirimi() {
    if (this.localValue.para_birimi_id != ParaBirimi.TRY &&
        !this.localValue.takip_tarihli_kurlar.find((kur:any) => kur.para_birimi_id == this.localValue.para_birimi_id)) {
      let kur = new TakipTarihliKurEntity();
      kur.para_birimi = this.localValue.para_birimi;
      kur.para_birimi_id = this.localValue.para_birimi_id;
      kur.tutar = null;
      this.localValue.takip_tarihli_kurlar.push(kur);
    }
  }
}
